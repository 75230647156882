export enum EUserType {
    SUPER_ADMIN = 'SUPER_ADMIN',
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    BRANCH_ADMIN = 'BRANCH_ADMIN'
}

export enum EUserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    REGISTERED = 'REGISTERED'
}

export enum ELoginStatus {
    LOGGED_IN = 'LOGGED_IN'
}

export enum EUserTypes {
    PDI_MANAGER = 'PDI_MANAGER',
    DEMO_MANAGER = 'DEMO_MANAGER',
    CUSTOMER_SERVICE_MANAGER = 'CUSTOMER_SERVICE_MANAGER',
    MOBILE_SERVICE_VAN_MANAGER = 'MOBILE_SERVICE_VAN_MANAGER',
    DELIVERY_VAN_MANAGER = 'DELIVERY_VAN_MANAGER',
    USED_CARS_MANAGER = 'USED_CARS_MANAGER',
    SHOWROOM_MANAGER = 'SHOWROOM_MANAGER',
    SERVICE_CENTER_MANAGER = 'SERVICE_CENTER_MANAGER',
    STOCKYARD_MANAGER = 'STOCKYARD_MANAGER',
    CASHIER = 'CASHIER',
    SECURITY = 'SECURITY',
    WORKSHOP_MANAGER = 'WORKSHOP_MANAGER',
    FRONT_OFFICE_DESK = 'FRONT_OFFICE_DESK'
}